import axios from "axios";
import { toast } from "react-toastify";

export const apiUrl = " https://77nv8irocd.execute-api.eu-west-2.amazonaws.com";

export const updateProfileUrl = `${apiUrl}/taginfo_profiles_update`;
export const getProfileUrl = `${apiUrl}/taginfo_profiles_get`;
export const updateTagUrl = `${apiUrl}/taginfo_tags_update`;
export const activateTagUrl = `${apiUrl}/taginfo_tags_activate`;
export const getTagsUrl = `${apiUrl}/taginfo_tags_list`;
export const getTagDetailsUrl = `${apiUrl}/taginfo_tag_data_get`;
export const createOrderUrl = `${apiUrl}/taginfo_orders_create`;

export const buildAxiosConfig = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const makeApiRequest = async (
  token: string,
  apiUrl: string,
  data?: any,
  onError = () => {}
) => {
  try {
    const req = [getProfileUrl, getTagsUrl].includes(apiUrl)
      ? axios.get(apiUrl, buildAxiosConfig(token))
      : axios.post(apiUrl, data, buildAxiosConfig(token));

    return (await req).data;
  } catch (error) {
    onError();
    toast.error("Error - Too many requests.");
    return false;
  }
};
