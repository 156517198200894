import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBriefcase,
  faKey,
  faPaw,
  faPhone,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

function Home() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="flex flex-col w-full">
      <p className="font-bold text-xl underline mb-4">
        Keep things safe with taginfo
      </p>
      <div className="flex flex-row mb-4">
        <div className="flex flex-col w-24">
          <FontAwesomeIcon icon={faPaw} size="2xl" />
          <p className="font-bold text-xl text-center">Pets</p>
        </div>
        <div className="flex flex-col w-24">
          <FontAwesomeIcon icon={faKey} size="2xl" />
          <p className="font-bold text-xl text-center">Keys</p>
        </div>
        <div className="flex flex-col w-24">
          <FontAwesomeIcon icon={faBriefcase} size="2xl" />
          <p className="font-bold text-xl text-center">Bags</p>
        </div>
        <div className="flex flex-col w-24">
          <FontAwesomeIcon icon={faTag} size="2xl" />
          <p className="font-bold text-xl text-center">Etc.</p>
        </div>
      </div>
      <p className="font-bold text mb-2">
        <span className="text-lg font-bold">Pets</span>: In the UK its a legal
        requirement that dogs wear a collar with the owners and and address on.
      </p>
      <p className="font-bold text mb-4">
        <span className="text-lg font-bold">Keys</span>: Useful for car/house
        keys or vehicle fleet businesses.
      </p>
      <p className="font-bold text mb-4">
        <span className="text-lg font-bold">Bags</span>: Great for luggage and
        backpacks, especially useful for school.
      </p>
      <p className="font-bold text mb-8">
        We highly recommend only displaying a phone number for sensitive items
        such as a childs backpack or vehicle keys.
      </p>

      <p className="font-bold text-xl underline mb-4">
        Update your info in our webapp
      </p>
      <div className="flex flex-row mb-4">
        <div className="flex flex-col w-28">
          <FontAwesomeIcon icon={faAddressCard} size="2xl" />
          <p className="font-bold text-xl text-center">addresses</p>
        </div>
        <div className="flex flex-col w-28">
          <FontAwesomeIcon icon={faPhone} size="2xl" />
          <p className="font-bold text-xl text-center">numbers</p>
        </div>
        <div className="flex flex-col w-28">
          <FontAwesomeIcon icon={faTag} size="2xl" />
          <p className="font-bold text-xl text-center">tag info</p>
        </div>
      </div>

      {!isAuthenticated && (
        <button
          className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded mb-8"
          onClick={() => loginWithRedirect()}
        >
          Login to manage your tags
        </button>
      )}

      {/* <div className="flex flex-row justify-center lg:justify-start w-full lg:mb-10">
        <img
          className="mb-4 border-2 border-black rounded"
          src="https://qrtag-public.s3.eu-west-2.amazonaws.com/homeimg.png"
          width={500}
        />
      </div> */}
      <p className="font-bold text-xl underline mb-2 mt-4">No subscriptions</p>
      <p className="font-bold text mb-8">
        We will not charge you to use our platform. You just need to buy your
        tags.
      </p>

      <a
        href="mailto:support@taginfo.co.uk"
        className="font-bold text-xl underline cursor-pointer"
      >
        contact support
      </a>
    </div>
  );
}

export default Home;
