import React, { useEffect } from "react";
import Input from "../components/Input";
import { useAuth0 } from "@auth0/auth0-react";
import { getProfileUrl, makeApiRequest, updateProfileUrl } from "../api";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import ModalList from "../components/ModalList";

export interface ListData {
  uuid: string;
  name: string;
  value: string;
  delete?: true;
}

export interface ProfileData {
  firstName?: string;
  lastName?: string;
  email?: string;
  addresses?: ListData[];
  phoneNumbers?: ListData[];
}

function Profile() {
  const [profileData, setProfileData] = React.useState({} as ProfileData);
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const getProfile = async () => {
    const token = await getAccessTokenSilently();
    const profileData = (await makeApiRequest(token, getProfileUrl))?.profile;
    setProfileData({
      firstName: profileData?.firstName ?? user?.given_name,
      lastName: profileData?.lastName ?? user?.family_name,
      email: user?.email,
      addresses: profileData?.addresses ?? [],
      phoneNumbers: profileData?.phoneNumbers ?? [],
    });
    setLoading(false);
    setUpdating(false);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleProfileDataChange = (name: string, value: any) => {
    const newProfileData = {
      ...profileData,
      ...{ [name]: value },
    };
    setProfileData(newProfileData);
  };

  const handleUpdate = async (profileDataOverride?: any) => {
    if (updating) return;
    setUpdating(true);
    const token = await getAccessTokenSilently();
    const data = await makeApiRequest(
      token,
      updateProfileUrl,
      profileDataOverride ? profileDataOverride : profileData
    );

    if (!data.success) {
      toast.error("An Error Occurred.");
    }

    if (data.errors.length) {
      data.errors.forEach((err: string) => toast.error(err));
    } else {
      toast.success("Updated your profile.");
    }
    getProfile();
  };
  const isOauth = !user?.sub?.includes("auth0|");

  return (
    <div className="flex flex-col w-full">
      {loading ? (
        <Loading />
      ) : (
        <>
          <p className="text-xl font-bold mb-4">My Profile</p>
          <div className="flex flex-col items-center rounded bg-white shadow-lg mb-4 w-full">
            <Input
              disabled
              name="email"
              label="Email Address"
              value={profileData.email}
              onChange={handleProfileDataChange}
            />
            <div className="flex w-full">
              <Input
                disabled={isOauth}
                name="firstName"
                label="First Name"
                value={profileData.firstName}
                onChange={handleProfileDataChange}
              />
              <Input
                disabled={isOauth}
                name="lastName"
                label="Last Name"
                value={profileData.lastName}
                onChange={handleProfileDataChange}
              />
            </div>
          </div>
          {!isOauth && (
            <button
              className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded border-2 border-slate-950 mb-10"
              onClick={handleUpdate}
            >
              {updating ? <Loading color="white" small /> : "Save Details"}
            </button>
          )}

          <p className="text-xl font-bold mb-4">My Addresses</p>
          <ModalList
            updating={updating}
            items={profileData.addresses}
            name="Address"
            handleUpdate={async (key: number, address: ListData) => {
              const newProfileData = Object.assign({}, profileData);
              if (!newProfileData.addresses) return;
              newProfileData.addresses = newProfileData.addresses.map(
                (a, k) => {
                  if (k === key) return address;
                  return a;
                }
              );
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
            handleSave={async (address: ListData) => {
              const newProfileData = {
                ...profileData,
                ...{ addresses: [...(profileData.addresses ?? []), address] },
              };
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
            handleDelete={async (uuid: string) => {
              const newProfileData = Object.assign(profileData, {});
              newProfileData.addresses?.map((address) => {
                if (address.uuid === uuid) address.delete = true;
              });
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
          />

          <p className="text-xl font-bold mb-4">My Phone Numbers</p>
          <ModalList
            updating={updating}
            items={profileData.phoneNumbers}
            name="Phone Number"
            handleUpdate={async (key: number, phoneNumber: ListData) => {
              const newProfileData = Object.assign({}, profileData);
              if (!newProfileData.phoneNumbers) return;
              newProfileData.phoneNumbers = newProfileData.phoneNumbers.map(
                (a, k) => {
                  if (k === key) return phoneNumber;
                  return a;
                }
              );
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
            handleSave={async (phoneNumber: ListData) => {
              const newProfileData = {
                ...profileData,
                ...{
                  phoneNumbers: [
                    ...(profileData.phoneNumbers ?? []),
                    phoneNumber,
                  ],
                },
              };
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
            handleDelete={async (uuid: string) => {
              const newProfileData = Object.assign(profileData, {});
              newProfileData.phoneNumbers?.map((phoneNumber) => {
                if (phoneNumber.uuid === uuid) phoneNumber.delete = true;
              });
              setProfileData(newProfileData);
              await handleUpdate(newProfileData);
            }}
          />
        </>
      )}
    </div>
  );
}

export default Profile;
