import React from "react";
import Card from "../components/Card";
import Modal from "../components/Modal";
import Input from "../components/Input";
import { createOrderUrl, makeApiRequest } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";

const pricing = [
  { max: 1, price: 10 },
  { min: 2, max: 3, price: 9 },
  { min: 4, max: 10, price: 8 },
  { min: 11, max: 99, price: 7 },
  { min: 100, max: 0, price: 5 },
];

interface Variant {
  text: string;
  color: string;
}

const variants = [
  { color: "bg-black", text: "black" },
  { color: "bg-purple-600", text: "purple" },
  { color: "bg-rose-200", text: "pink" },
  { color: "bg-blue-700", text: "blue" },
];

const charities = [
  { color: "bg-lime-200", text: "Morocco Animal Shelter" },
  { color: "bg-yellow-500", text: "Dogs Trust" },
  { color: "bg-black", text: "RSPCA" },
  { color: "bg-slate-300", text: "Cats Protection" },
  { color: "bg-rose-200", text: "Animal Health Trust" },
];

function Purchase() {
  const navigate = useNavigate();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [pricingModalIsOpen, setPricingModalIsOpen] = React.useState(false);
  const [subtotal, setSubtotal] = React.useState(10);
  const [bulkDiscount, setBulkDiscount] = React.useState(0);
  const [total, setTotal] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [qty, setQty] = React.useState(1);

  const variantDefaults = {} as any;
  variants.forEach((v, k) => {
    variantDefaults[v.text] = k === 0 ? 1 : 0;
  });
  const [variantTotals, setVariantTotals] = React.useState(variantDefaults);

  const setToFixed = (m: any, n: number) => m(n.toFixed(2));

  const handleQtyChange = (variant: Variant, qty: number) => {
    const newVariantTotals = { ...variantTotals, ...{ [variant.text]: qty } };

    if (qty === -1) return;

    let newQty = 0;
    Object.keys(newVariantTotals).forEach((v) => {
      newQty = newQty + newVariantTotals[v];
    });

    const subtotal = pricing[0].price * newQty;
    const price =
      pricing.find(
        (p) => (p.min ?? 1) <= newQty && (p.max >= newQty || p.max === 0)
      )?.price ?? 0;
    const total = price * newQty;

    setQty(newQty);
    setToFixed(setSubtotal, subtotal);
    setToFixed(setBulkDiscount, total - subtotal);
    setToFixed(setTotal, total);
    setVariantTotals(newVariantTotals);
  };

  return (
    <div className="flex flex-col w-full">
      <p className="text-xl font-bold">Order new tags</p>
      <p className="font-bold mb-4">one time purchase, no subscriptions.</p>

      <Card>
        <div className="lg:flex lg:flex-row">
          <div className="flex flex-row justify-center p-4 lg:mb-8 lg:w-1/2">
            <img
              src="https://qrtag-public.s3.eu-west-2.amazonaws.com/taginfo_product1.png"
              className="border-4 border-slate-950 rounded object-fit"
            />
          </div>

          <div className="flex flex-col justify-between px-4 py-2 lg:py-4 lg:justify-normal lg:mb-8 lg:w-1/2">
            <p
              className="font-bold mb-2 cursor-pointer underline"
              onClick={() => setPricingModalIsOpen(true)}
            >
              (Bulk Discount Information)
            </p>

            <p className="font-bold mb-2">Choose your quantities:</p>
            {variants.map((variant) => (
              <div className="flex flex-row w-full justify-between items-center mb-2">
                <div className="flex flex-row items-center">
                  <div
                    className={`rounded-full w-8 h-8 mr-2 ${variant.color}`}
                  ></div>
                  <p className="font-bold text-left">{variant.text}</p>
                </div>
                <div className="flex flex-row justify-between">
                  <div
                    className="px-2 text-xl bold rounded border-2 border-slate-950 cursor-pointer mr-2"
                    onClick={() =>
                      handleQtyChange(variant, variantTotals[variant.text] - 1)
                    }
                  >
                    -
                  </div>
                  <Input
                    noPadding
                    type="number"
                    value={variantTotals[variant.text]}
                    classes="text-center"
                    width={8}
                    style={{ width: "60px" }}
                    onChange={(_: string, v: number) => {
                      handleQtyChange(variant, Number(v));
                    }}
                  />
                  <div
                    className="px-2 text-xl bold rounded border-2 border-slate-950 bg-slate-950 text-white cursor-pointer ml-2"
                    onClick={() =>
                      handleQtyChange(variant, variantTotals[variant.text] + 1)
                    }
                  >
                    +
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>

      {/* <Card>
        <p className="font-bold mb-2">Choose your charity (10%)</p>
        <p className="mb-2">
          <span className="font-bold underline">{`Charity`}</span> will
          receive&nbsp;
          <span className="font-bold underline">
            {`£${(total / 10).toFixed(2)}`}
          </span>
          &nbsp;from us thanks to <span className="font-bold">your</span> order.
        </p>
        <button className="px-4 py-2 bg-white text-slate-950 hover:bg-slate-200 rounded border-2 border-slate-950 mb-2">
          Pick a Charity
        </button>
      </Card> */}

      <Card>
        <div className="flex justify-between">
          <p>Subtotal</p>
          <p>£{subtotal}</p>
        </div>
        <div className="flex justify-between text-lime-600">
          <p>Bulk Discount</p>
          <p>{`- £${bulkDiscount}`}</p>
        </div>
        <div className="flex justify-between">
          <p>Shipping</p>
          <p>Free (UK)</p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="font-bold">Total</p>
          <p>£{total}</p>
        </div>
      </Card>

      <button
        className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded border-2 border-slate-950 mb-2"
        onClick={async () => {
          if (loading) return;
          setLoading(true);
          let token = "";
          if (isAuthenticated) {
            token = await getAccessTokenSilently();
          }

          const res = await makeApiRequest(token, createOrderUrl, {
            quantities: variantTotals,
          });
          const paymentLink = res?.paymentLink;

          setLoading(false);
          window.location.href = paymentLink;
        }}
      >
        {loading ? <Loading color="white" small /> : "Buy"}
      </button>

      <Modal modalIsOpen={pricingModalIsOpen}>
        <div className="flex flex-row w-44">
          <p
            className="font-bold mb-2 cursor-pointer"
            onClick={() => setPricingModalIsOpen(false)}
          >
            x
          </p>
          <p className="font-bold mb-8 ml-4 underline">Bulk Discount Pricing</p>
        </div>

        {pricing.map((price, k) => {
          return (
            <div className="flex justify-between" key={k}>
              <p className="font-bold mb-2">
                {price.min && price.max ? `${price.min}-` : ""}
                {price.max === 0 ? `${price.min} or more` : price.max} tag
                {price.max !== 1 ? "s" : ""}
              </p>
              <p className="font-bold mb-2">£{price.price.toFixed(2)}</p>
            </div>
          );
        })}
      </Modal>
    </div>
  );
}

export default Purchase;
